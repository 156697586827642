/* Import Tailwind CSS */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Reset some default styles */
* {
  box-sizing: border-box;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar,
  .hide-scrollbar::-webkit-scrollbar,
  .email-view-content::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
    background: transparent;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar,
  .hide-scrollbar,
  .email-view-content {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}

/* App container takes full height */
.App {
  height: 100vh;
  background-color: #111111;
  color: white;
  min-width: 320px; /* Prevent layout from breaking on very small screens */
}

/* Custom scrollbar for elements that should show it */
.show-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.show-scrollbar::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.show-scrollbar::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 3px;
}

.show-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #444;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  animation: fadeIn 0.3s ease-in-out;
}

/* Utility classes */
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp-1 {
  display: -webkit-box;
  display: -moz-box;
  -webkit-line-clamp: 1;
  -moz-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  display: -moz-box;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  overflow: hidden;
}

/* Modal animations */
.modal-enter {
  opacity: 0;
  transform: scale(0.9);
}

.modal-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.modal-exit {
  opacity: 1;
}

.modal-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

/* Dark theme overrides */
.prose {
  color: #e5e7eb;
}

.prose a {
  color: #60a5fa;
}

.prose strong {
  color: white;
}

.prose h1,
.prose h2,
.prose h3,
.prose h4,
.prose h5,
.prose h6 {
  color: white;
}

/* Custom focus styles */
*:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(96, 165, 250, 0.5);
}

/* Button hover effects */
button {
  transition: all 0.2s ease-in-out;
}

button:hover {
  transform: translateY(-1px);
}

/* Priority badge styles */
.priority-badge {
  background: rgba(245, 158, 11, 0.1);
  color: #f59e0b;
  font-size: 10px;
  text-transform: uppercase;
  padding: 2px 8px;
  border-radius: 4px;
}

/* Small screen optimizations */
@media (max-width: 380px) {
  /* Improve text readability on small screens */
  .line-clamp-1,
  .line-clamp-2 {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .priority-badge {
    padding: 1px 6px;
    font-size: 9px;
  }

  /* Adjust text sizes for very small screens */
  h1 {
    font-size: 1.25rem !important;
  }

  /* Optimize spacing for small screens */
  .email-item {
    padding: 0.75rem !important;
  }

  /* Adjust button sizes */
  button {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  /* Optimize navigation spacing */
  .nav-item {
    padding: 0.5rem !important;
  }

  /* Adjust avatar size */
  .avatar {
    width: 28px !important;
    height: 28px !important;
  }
}

/* Avatar styles */
.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: white;
  text-transform: uppercase;
}

/* Email list item hover effect */
.email-item:hover {
  background-color: #1a1a1a;
}

/* Email content styles */
.email-content-container {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.6;
  color: #CCCCCC;
  background-color: #000;
}

/* This class preserves the original email styling */
.email-original-content {
  /* Reset any styling that might interfere with the original email */
  all: revert;
  font-family: inherit;
  color: inherit;
  
  /* Ensure proper display in dark mode */
  background-color: transparent !important;
  color: #CCCCCC !important;
}

/* Ensure images don't overflow */
.email-original-content img {
  max-width: 100% !important;
  height: auto !important;
}

/* Ensure tables don't overflow */
.email-original-content table {
  max-width: 100% !important;
  width: auto !important;
}

/* Ensure links are visible */
.email-original-content a {
  color: #FFB27D !important;
  text-decoration: none !important;
}

.email-original-content a:hover {
  text-decoration: underline !important;
}

/* Fix Gmail quote styling */
.email-original-content .gmail_quote {
  border-left: 2px solid #333 !important;
  padding-left: 10px !important;
  margin-left: 0 !important;
  color: #999 !important;
}

/* Fix for inline styles that might use light colors on dark background */
.email-original-content [style*="color"] {
  color: inherit !important;
}

/* Fix for background colors */
.email-original-content [style*="background"] {
  background-color: transparent !important;
}

/* Email content styles are now handled by email-original-content */
